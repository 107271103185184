$highlight-color: rgba(236, 72, 153, 1);

.simpleTableStyles {
    th {
        background-color: #f3f4f6;
        box-shadow:
            0.5px 0 0 0 rgba(209, 213, 219, 1);
    }

    th:hover {
        box-shadow: inset 0 0 0 1px $highlight-color,
    }

    th>div {
        backdrop-filter: none;
        /* 모든 backdrop-filter 제거 */
    }

    tbody>tr:hover {
        box-shadow: inset 0 0 0 1px $highlight-color;
    }

    tbody>tr:hover td {
        box-shadow:
            inset 0 -2px 0px -1px $highlight-color,
            inset 0 2px 0px -1px $highlight-color;
    }

    :global {
        .selectedRow {
            box-shadow:
                inset 0 0 0 2px $highlight-color !important;
        }

        .selectedRow td {
            box-shadow:
                inset 0 -3px 0px -1px $highlight-color,
                inset 0 3px 0px -1px $highlight-color !important;
        }

    }
}

.stickyFirstCol {
    th:first-child {
        position: sticky;
        left: 0;
        background-color: #f3f4f6; // bg-gray-100
        box-shadow:
            inset 1px 0 0 -0.5px rgba(209, 213, 219, 1),
            inset -1px 0 0 -0.5px rgba(209, 213, 219, 1),
            // margin: 3px;
    }

    td:first-child {
        position: sticky;
        left: 0;
        background-color: rgb(249 250 251); // bg-gray-50
        box-shadow:
            inset 1px 0 0 -0.5px rgba(209, 213, 219, 1),
            inset -1px 0 0 -0.5px rgba(209, 213, 219, 1),
            // margin: 3px;
    }

    tbody>tr:hover td:first-child {
        box-shadow:
            inset 0 -2px 0px -1px $highlight-color,
            inset 0 2px 0px -1px $highlight-color,
            inset 2px 0 0 -1px $highlight-color,
            inset -1px 0 0 -0.5px rgba(209, 213, 219, 1),
    }

    :global {
        .selectedRow td:first-child {
            box-shadow:
                inset 0 -3px 0px -1px $highlight-color,
                inset 0 3px 0px -1px $highlight-color,
                inset 3px 0 0 -1px $highlight-color,
                inset 1px 0 0 -0.5px rgba(209, 213, 219, 1),
                inset -1px 0 0 -0.5px rgba(209, 213, 219, 1) !important;
        }
    }
}