:root {
    --sidenav-width: 250px;
}

.sidenav-spacer {
    width: var(--sidenav-width);
    z-index: 0;
}

.sidenav-content {
    width: var(--sidenav-width);
    height: 100%;
    z-index: 100;
    position: fixed;
    display: block;
    overflow-y: auto;
    max-width: 100%;
    box-shadow: 3px 3px 10px rgb(0 0 0 / 10%);
    transition: all 300ms;
}

.sidenav-dimmed {
    display: absolute;
    left: 0;
    right: 0;
    opacity: 0;
    /* transition: all 500ms; */
}

.sidenav-hamburger {
    display: none;
}

input#toggle-sidenav[type="checkbox"] {
    display: none;
}

@media only screen and (max-width: 640px) { // 640px is sm, 768px is md, 1024px is lg, 1280px is xl, 1536px is 2xl
    .sidenav-content {
        opacity: 1;
        width: var(--sidenav-width);
        height: 100%;
        z-index: 100;
        position: fixed;
        /* display: none; */
        max-width: 0%;
    }

    .sidenav-dimmed {
        background-color: gray;
        /* left: var(--sidenav-width); */
        left: 0;
        right: 0;
        height: 100vh;
        z-index: 100;
        position: fixed;
        display: block;
        max-width: 0;
        opacity: 0;
    }

    .sidenav-hamburger {
        top: 10px;
        left: 10px;
        z-index: 100;
        text-align: center;
        position: fixed;
        display: block;
    }

    input#toggle-sidenav:checked ~ .sidenav-content {
        display: block;
        max-width: 100%;
    }

    input#toggle-sidenav:checked ~ .sidenav-dimmed {
        display: block;
        max-width: 100%;
        opacity: 0.3;
    }

    input#toggle-sidenav:checked ~ .sidenav-hamburger {
        display: none;
    }
}
